<mat-expansion-panel
  class="mt-4 accordion"
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
  id="accordion-requirements-{{titleAccordion}}"
>
  <mat-expansion-panel-header>
    <mat-panel-title class="title">{{ headerTable.title }} </mat-panel-title>
  </mat-expansion-panel-header>
  <button
    mat-flat-button
    class="btn-grey mt-4"
    (click)="openDialogRequirementsRegister()"
    id="btn-add-{{ titleAccordion }}"
  >
    + Adicionar
  </button>

  <table
    mat-table
    [dataSource]="datasource"
    class="table mt-4 mat-elevation-z2"
  >
    <caption hidden>
      Tabela minhas empresas
    </caption>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef class="table-head-color" id="table-header-{{ titleAccordion }}">
        {{ headerTable.title }}
      </th>
      <td mat-cell *matCellDef="let el; let id = index" class="table-row">
        {{ el.label }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-head-color column-actions"
        id="table-header-{{ headerTable.actions }}-{{titleAccordion}}"
      >
        {{ headerTable.actions }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let rowIndex = index"
        class="table-row"
      >
        <button
          class="btn-trash"
          (click)="remove(element.labelWithUndescore, headerTable.title)"
          id="btn-trash-{{ titleAccordion }}-{{ rowIndex }}"
        >
          <i class="ri-delete-bin-5-fill"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-expansion-panel>
