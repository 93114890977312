import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { RespostaLogin } from 'src/app/model/authentication/respostaLogin';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoginService } from './login.service';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ConfigurationsShipperService } from 'src/app/configurations-shipper/configurations-shipper.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: any = FormGroup;
  dataUser: RespostaLogin = new RespostaLogin();
  typePassword: string = 'password';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly loginService: LoginService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private _snackBar: SnackbarDefaultService,
    private dialog: MatDialog,
    private configurationsShipperService: ConfigurationsShipperService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    let heaveToken = this.authService.getToken();
    if (heaveToken) this.authService.removeToken();
  }

  send() {
    if (this.form.valid) {
      const authentication = {
        email: this.form.value.email,
        senha: this.form.value.password,
      };

      this.loginService
        .login(authentication)
        .pipe(first())
        .subscribe({
          next: (result) => {
            if (result.status != 200 && result.codigoHttp != 200) {
              this.dataUser.codigoHttp = result?.status;
            } else {
              this.dataUser = result;
            }
            this.checkCodeAndDefineRoute(result);
          },
        });
    }
  }

  checkCodeAndDefineRoute(result: any) {
    const successCode: number = 200;
    const unauthorizedCode: number = 401;
    const serverErrorCode: number = 500;
    let message: string = '';
    switch (this.dataUser?.codigoHttp) {
      case successCode:
        this.authService.putToken(this.dataUser.token);
        this.authService.putUsuario(this.dataUser.usuario);
        message = 'Bem-vindo à Plataforma de Fretes!';
        this._snackBar.openSnackBarSuccess(message);
        if (
          this.dataUser.usuario.cliente &&
          this.dataUser.usuario.cliente.ofertaFrete
        ) {
          this.router.navigate(['shipper']);
          this.checkPlan();
        } else {
          let url = sessionStorage.getItem('rota');
          if (url?.includes('carriers/offers/offer-details')) {
            this.router.navigate([url]);
          } else {
            this.router.navigate(['carriers']);
          }
        }
        break;
      case unauthorizedCode:
        this._snackBar.openSnackBarError(result.error.mensagemRetorno);
        this.authService.logout();
        break;
      case serverErrorCode:
        // exibindo erro no servidor
        break;
      default:
        console.log('default');
      // nao foi possivel logar
    }
  }

  showPassword(event: any) {
    if (event.target.checked) {
      this.typePassword = 'text';
    } else {
      this.typePassword = 'password';
    }
  }

  forgotPassword() {
    this.dialog.open(ForgotPasswordComponent);
  }

  checkPlan() {
    this.configurationsShipperService
      .getTotalOfferts()
      .pipe(first())
      .subscribe((data) => {
        if (data) {
          if(data.plano?.toLowerCase() == 'trial'){
            let obj = {
              quantidadeOfertasPlano: data.quantidadeOfertasPlano,
              ofertasMes: data.ofertasMes
            }
            localStorage.setItem('ofertasTrial', JSON.stringify(obj))
          }
        }
      });
  }
}
