<mat-card class="container-supplier card-container" *ngIf="loaded; else isLoading">
  <mat-card-content>
    <h6 class="mt-2">Meus fornecedores</h6>

    <div class="d-flex mt-4">
      <div class="flex-grow-1">
        <a
          mat-flat-button
          class="btn-grey"
          [routerLink]="[newSupplier]"
          id="btn-new-supplier"
          ><i class="ri-add-line"></i> Novo
        </a>
      </div>
      <div class="d-flex flex-row-reverse">
        <form
          class="select-form d-flex"
          [formGroup]="searchForm"
          (keyup)="submitForm()"
        >
          <div class="form-group position-relative search">
            <input
              matInput
              id="search"
              name="search"
              formControlName="search"
              type="text"
              class="form-control pe-5 input-data w-100"
              placeholder="Buscar por cnpj"
              appNumeroInt
            />
            <i
              class="ri-search-line position-absolute end-0 my-1 mx-2 text-center"
              style="top: 5px; cursor: pointer"
              id="btn-search-supplier"
            ></i>
          </div>
        </form>
      </div>
    </div>

    <div class="mt-2 mat-table-container" *ngIf="!loadingContent; else isLoading">
      <div *ngIf="dataSource.data?.length > 0; else noData">
        <table mat-table [dataSource]="dataSource" class="mat-table mt-4">
          <caption hidden>
            Tabela fornecedores
          </caption>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-supplier-name">
              {{ headerTable.lbName }}
            </th>
            <td mat-cell *matCellDef="let element" class="table-row">
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-supplier-document">
              {{ headerTable.lbDocument }}
            </th>
            <td mat-cell *matCellDef="let element" class="table-row">
              {{ element.document }}
            </td>
          </ng-container>

          <ng-container matColumnDef="uf">
            <th mat-header-cell *matHeaderCellDef class="table-head-color" id="header-table-supplier-province">
              {{ headerTable.lbUf }}
            </th>
            <td mat-cell *matCellDef="let element" class="table-row">
              {{ element.address.province }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="table-head-color column-actions"
              id="header-table-supplier-actions"
            >
              {{ headerTable.lbActions }}
            </th>
            <td mat-cell *matCellDef="let element; let index = index" class="table-row">
              <button
                mat-button
                [matMenuTriggerFor]="menu"
                class="dropdown-button"
                id="btn-actions-supplier-{{ index }}"
              >
                {{ headerTable.lbActions }}
                <i class="ri-arrow-down-s-line"></i>
              </button>
              <mat-menu #menu="matMenu">
                <a
                  mat-menu-item
                  class="dropdown-button-item"
                  *ngFor="let action of options; let i = index"
                  (click)="redirect(action, element)"
                  id="btn-options-action-{{action}}-supplier-{{ index }}-{{i}}"
                >
                  {{ action }}
                </a>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (dblclick)="redirectDetails(row)" id="row-table-supplier-{{i}}"></tr>
        </table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 20, 50, 100]"
          showFirstLastButtons
          aria-label="Select page of periodic elements"
          (page)="handlePageEvent($event)"
          [length]="totalElements"
          [pageIndex]="page"
          [pageSize]="size"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #isLoading>
  <mat-progress-bar mode="indeterminate" color="primary" style="margin-top: 30px;"></mat-progress-bar>
</ng-template>

<ng-template #noData>
  <mat-card class="card-details container-top no-data">
    <mat-card-content>
      <div class="content">
        <i class="ri-survey-line icon-message"></i>
        <div>
          <div>
            <p class="title">Nenhum resultado encontrado!</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
