<div *ngFor="let title of listTitle; let i = index">
  <app-requirements
    [uniqueId]="i"
    (updateData)="getCompany()"
    [title]="title"
    [datasource]="listTables[i]"
    [headerTable]="listHeader[i]"
    [displayedColumns]="displayedColumns[i]"
  ></app-requirements>
</div>
