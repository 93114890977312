import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumeroInt]'
})
export class MaskIntDirective {

  constructor() { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const regex = /[^\d]/g;
    input.value = input.value.replace(regex, '');
  }
}
